<template>
  <b-row>
    <b-col cols="12">
      <statistics />
    </b-col>
    <b-col cols="12">
      <services />
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
// eslint-disable-next-line import/extensions
import Statistics from '@/views/App/Dashboard/Statistics'
// eslint-disable-next-line import/extensions
import Services from '@/views/App/Dashboard/Services'

export default {
  name: 'DashboardIndex',
  components: {
    BRow,
    BCol,
    Statistics,
    Services,
  },
}
</script>
