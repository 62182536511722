<template>
  <b-row>
    <b-col cols="12">
      <statistics />
    </b-col>
    <b-col
      xs="12"
      md="8"
    >
      <services />
    </b-col>
    <b-col
      xs="12"
      md="4"
    >
      <service-requests />
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
// eslint-disable-next-line import/extensions
import Statistics from '@/views/Dashboard/Manager/Statistics'
// eslint-disable-next-line import/extensions
import Services from '@/views/Dashboard/Manager/Services'
// eslint-disable-next-line import/extensions
import ServiceRequests from '@/views/Dashboard/Manager/Service_requests'

export default {
  name: 'DashboardIndex',
  components: {
    BRow,
    BCol,
    Statistics,
    Services,
    ServiceRequests,
  },
}
</script>
