<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>Son Servis İşlemleri</b-card-title>
    </b-card-header>
    <div
      v-if="services.length > 0"
      class="overflow-scroll"
    >
      <b-table
        responsive="sm"
        :fields="fields"
        :items="services"
        striped
        borderless
      >
        <template #cell(customer)="data">
          <div class="font-weight-bold">
            {{ data.item.customer }}
          </div>
          <div class="text-primary font-small-2">
            <strong>İlgili Kişi: </strong> {{ data.item.related_person }}
          </div>
          <div
            v-if="data.item.city_out1 && data.item.city_out2"
            class="text-muted font-small-2"
          >
            {{ data.item.city_out1 }} > {{ data.item.city_out2 }}
          </div>
        </template>
        <template #cell(work_type)="data">
          <div>
            {{ data.item.work_type }}
          </div>
          <div class="text-primary font-small-2">
            {{ data.item.job }}
          </div>
        </template>
        <template #cell(id)="data">
          <div class="text-right">
            <b-button
              variant="flat-primary"
              class="btn-icon rounded-circle"
              :to="'app/services/view/' + data.item.id"
            >
              <FeatherIcon icon="EyeIcon" />
            </b-button>
          </div>
        </template>
      </b-table>
    </div>
    <b-card-body v-else>
      <b-alert
        show
        variant="info"
      >
        <div class="alert-body text-center">
          <FeatherIcon
            icon="CheckIcon"
            size="32"
          />
          <p>Geçmiş dönem servis işlemi bulunamadı.</p>
        </div>
      </b-alert>
    </b-card-body>
  </b-card>
</template>
<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BTable,
  BButton,
  BAlert,
} from 'bootstrap-vue'

export default {
  name: 'Services',
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BTable,
    BButton,
    BAlert,
  },
  data() {
    return {
      fields: [
        {
          key: 'customer',
          label: 'FİRMA ADI',
        },
        {
          key: 'work_type',
          label: 'ÇALIŞMA SINIFI - İŞ',
        },
        {
          key: 'id',
          label: '',
          thStyle: { width: '20px', textAlign: 'center', verticalAlign: 'middle' },
        },
      ],
    }
  },
  computed: {
    services() {
      return this.$store.getters['dashboard/getServices']
    },
  },
  created() {
    this.getServices()
  },
  methods: {
    getServices() {
      this.$store.dispatch('dashboard/services')
    },
  },
}
</script>
